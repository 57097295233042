import { environments } from './commons';

const DOMAINS = {
  [environments.DEV]: 'https://api.dev.larvia.ai',
  [environments.STG]: 'https://api.stg.larvia.ai',
  [environments.PROD]: 'https://api.larvia.ai'
};

const URLS_DASHBOARD = {
  local: 'http://localhost:4000',
  [environments.DEV]: 'https://test.larvia.ai',
  [environments.STG]: 'https://stg.larvia.ai',
  [environments.PROD]: 'https://app.larvia.ai'
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || environments.DEV;

const DOMAIN = DOMAINS[ENVIRONMENT];

const USERS_SERVICE_URL = `${DOMAIN}/users`;
const AUTH_SERVICE_URL = `${DOMAIN}/auth`;
const ANALYSIS_SERVICE_URL = `${DOMAIN}/analysis`;
const BALANCE_SERVICE_URL = `${DOMAIN}/balance`;
const ANALYSIS_API_URL = `${DOMAIN}/analysis-api-endpoint`;

export const s3BucketURL = {
  [environments.DEV]: {
    BASE_URL: 'https://dev-invoices-stocking.s3.amazonaws.com/',
    VOUCHER_PREPAID: 'https://dev-prepaid-vouchers.s3.amazonaws.com/',
    VOUCHER_POSTPAID: 'https://dev-postpaid-vouchers.s3.amazonaws.com/',
  },
  [environments.STG]: {
    BASE_URL: 'https://stg-invoices-stocking.s3.amazonaws.com/',
    VOUCHER_PREPAID: 'https://stg-prepaid-vouchers.s3.amazonaws.com/',
    VOUCHER_POSTPAID: 'https://stg-postpaid-vouchers.s3.amazonaws.com/',
  },
  [environments.PROD]: {
    BASE_URL: 'https://prod-invoices-stocking.s3.amazonaws.com/',
    VOUCHER_PREPAID: 'https://prod-prepaid-vouchers.s3.amazonaws.com/',
    VOUCHER_POSTPAID: 'https://prod-postpaid-vouchers.s3.amazonaws.com/',
  },
};

// Auth URLS
export const SIGN_IN_URL = `${AUTH_SERVICE_URL}/authentication`;
export const REFRESH_TOKEN_URL = `${AUTH_SERVICE_URL}/authentication/refresh`;
export const PASSWORD_REQUEST = `${AUTH_SERVICE_URL}/password/request`;
export const PASSWORD_RESET = `${AUTH_SERVICE_URL}/password/reset`;
export const PASSWORD_RENEW = `${AUTH_SERVICE_URL}/password/renew`;
export const IMPERSONATE_URL = `${AUTH_SERVICE_URL}/users/impersonate`;
export const USER_ACTIVITY_DASHBOARD_URL = `${AUTH_SERVICE_URL}/user-activity`;

// Users URLS
export const CLIENTS_URL = `${USERS_SERVICE_URL}/clients`;
export const CLIENTS_PUBLIC_URL = `${USERS_SERVICE_URL}/public-clients`;
export const CLIENTS_PUBLIC_COUPON_URL = `${USERS_SERVICE_URL}/public-clients-coupon`;
export const USERS_URL = `${USERS_SERVICE_URL}/users`;
export const COMPANIES_URL = `${USERS_SERVICE_URL}/companies`;
export const PASSWORD_RESET_URL = `${USERS_SERVICE_URL}/users/password`;
export const ANALYSIS_DETAIL_PDF_URL = `${USERS_SERVICE_URL}/url-analysis-detail-pdf`;
export const STOCKING_CHARTS_PDF_URL = `${USERS_SERVICE_URL}/url-stocking-charts-pdf`;
export const STOCKING_COMPARISON_PDF_URL = `${USERS_SERVICE_URL}/url-sowing-comparison-pdf`;
export const MULTIPHASE_CHART_PDF_URL = `${USERS_SERVICE_URL}/url-multiphase-chart-pdf`;
export const MATURATION_COMPARISON_PDF_URL = `${USERS_SERVICE_URL}/url-maturation-comparison-pdf`;
export const GROWTH_DELTA_PDF_URL = `${USERS_SERVICE_URL}/url-growth-delta-pdf`;
export const SAMPLE_WEIGHT_LIMITS_URL = `${USERS_SERVICE_URL}/sample-weight`;
export const VOLUME_RANGES_URL = `${USERS_SERVICE_URL}/volume-range`;
export const STOCKING_DATE_RANGES_URL = `${USERS_SERVICE_URL}/stocking-date-range`;
export const COUPONS_URL = `${USERS_SERVICE_URL}/coupons`;
export const STOCKING_FINISHED_DATA_URL = `${USERS_SERVICE_URL}/stocking-finished`;
export const INTEGRATION_API_KEY_URL = `${USERS_SERVICE_URL}/settings/api-key`;
export const LABORATORY_COMPARISON_PDF_URL = `${USERS_SERVICE_URL}/url-laboratory-comparison-pdf`;
export const TANKS_QR_PDF_URL = `${USERS_SERVICE_URL}/tanks-qr-pdf`;
export const STOCKING_STAGE_OPTIONS_URL = `${USERS_SERVICE_URL}/stocking-stage-options`;
export const MYSQL_CREDENTIALS_URL = `${USERS_SERVICE_URL}/mysql-credentials`;
export const STOCKING_PARAMETER_PDF_URL = `${USERS_SERVICE_URL}/url-stocking-parameter-pdf`;
export const PARAMETER_STATUS_PDF_URL = `${USERS_SERVICE_URL}/url-parameter-state-pdf`;
export const HARVEST_HISTOGRAM_PDF_URL = `${USERS_SERVICE_URL}/url-harvest-histogram-pdf`;

// Analysis URLS
export const SHARE_ANALYSES = `${URLS_DASHBOARD[process.env.REACT_APP_ENVIRONMENT || 'local']}/production/analysis`;
export const STOCKINGS_DASHBOARD_URL = `${URLS_DASHBOARD[process.env.REACT_APP_ENVIRONMENT || 'local']}/production/stockings`;
export const PAYMENTS_SECTION_URL = `${URLS_DASHBOARD[process.env.REACT_APP_ENVIRONMENT || 'local']}/payments`;

export const ANALYSES_URL = `${ANALYSIS_SERVICE_URL}/analysis`;
export const CAMPUS_URL = `${ANALYSIS_SERVICE_URL}/campus`;
export const CAMPUS_MAPPING_URL = `${ANALYSIS_SERVICE_URL}/campus-mapping`;
export const MODULES_URL = `${ANALYSIS_SERVICE_URL}/modules`;
export const TANKS_URL = `${ANALYSIS_SERVICE_URL}/tanks`;
export const STOCKINGS_URL = `${ANALYSIS_SERVICE_URL}/sowings`;
export const BASE_MATURATIONS_URL = `${ANALYSIS_SERVICE_URL}/base-maturations`;
export const MATURATIONS_URL = `${ANALYSIS_SERVICE_URL}/companies-maturations`;
export const SHARED_ANALYSIS_URL = `${ANALYSIS_SERVICE_URL}/shared-analysis`;
export const USERS_USAGE_URL = `${ANALYSIS_SERVICE_URL}/analysis/usage/byUser`;
export const CAMPUS_USAGE_URL = `${ANALYSIS_SERVICE_URL}/analysis/usage/byCampus`;
export const STAGE_USAGE_URL = `${ANALYSIS_SERVICE_URL}/analysis/usage/byStage`;
export const CATEGORY_USAGE_URL = `${ANALYSIS_SERVICE_URL}/analysis/usage/byCategory`;
export const DISEASES_URL = `${ANALYSIS_SERVICE_URL}/diseases`;
export const RELAUNCH_ANALYSIS_URL = `${ANALYSES_URL}/relaunch`;
export const UNASSIGNED_STOCKINGS_URL = `${STOCKINGS_URL}/list/unassigned`;
export const ANALYSIS_BY_CATEGORY_URL = `${ANALYSES_URL}/list/byCategory`;
export const ACTIVE_TANKS_URL = `${TANKS_URL}/active-tanks/by-module`;
export const STOCKING_PARAMETER_GRAPH_URL = `${TANKS_URL}/active-tanks/parameters`;
export const COMPLETED_ANALYSIS_COUNT_URL = `${ANALYSES_URL}/usage/count`;
export const APP_USAGE_MONTHLY_RANGE_URL = `${ANALYSES_URL}/usage/monthly`;
export const APP_USAGE_BY_DATE_RANGE_URL = `${ANALYSES_URL}/usage/byTime`;
export const APP_USAGE_BY_DAY_URL = `${ANALYSES_URL}/usage/byDay`;
export const COMPANY_USAGE_URL = `${ANALYSIS_SERVICE_URL}/analysis/usage/byCompany`;
export const SURVIVAL_RATE_URL = `${ANALYSIS_SERVICE_URL}/survival-rate`;
export const SURVIVAL_RATE_HISTOGRAM_URL = `${ANALYSIS_SERVICE_URL}/survival-rate-histogram`;
export const GROWTH_DELTA_URL = `${ANALYSIS_SERVICE_URL}/growth-delta`;
export const STOCKINGS_HARVESTED_URL = `${ANALYSIS_SERVICE_URL}/sowings-harvested`;
export const STOCKINGS_TO_BE_FINISHED_URL = `${ANALYSIS_SERVICE_URL}/sowings-to-be-finished`;
export const STOCKINGS_WITHOUT_ANALYSIS_URL = `${ANALYSIS_SERVICE_URL}/sowings-with-analysis`;
export const ARCHIVE_ANALYSIS_URL = `${ANALYSES_URL}/archive`;
export const UNARCHIVE_ANALYSIS_URL = `${ANALYSES_URL}/unarchive`;
export const REMOVE_CONSOLIDATED_URL = `${ANALYSES_URL}/remove-consolidated`;
export const REFERENCE_CURVES_URL = `${ANALYSIS_SERVICE_URL}/reference-curves`;
export const REFERENCE_CURVES_BY_FILTERS_URL = `${REFERENCE_CURVES_URL}-by-filters`;
export const REFERENCE_CURVES_OF_THE_UNITS_URL = `${REFERENCE_CURVES_URL}-of-the-units`;
export const REFERENCE_CURVES_GROUPED_BY_DAYS_URL = `${REFERENCE_CURVES_URL}-in-group-of-days`;
export const STOCKING_BINDING_CODE_URL = `${ANALYSIS_SERVICE_URL}/stocking-binding-code`;
export const ANALYSIS_DETAIL_CUSTOMIZABLE_URL = `${ANALYSIS_SERVICE_URL}/analysis-detail-customizable`;
export const PREV_STATS_URL = `${ANALYSIS_SERVICE_URL}/analysis/stats/previous-tanks`;
export const QUADRANT_STAGES_URL = `${ANALYSIS_SERVICE_URL}/analysis/quadrant/stages`;
export const QUADRANT_DATA_URL = `${ANALYSIS_SERVICE_URL}/analysis/quadrant/data`;
export const GLOBAL_STOCKING_PARAMETER_URL = `${ANALYSIS_SERVICE_URL}/global-stocking-parameters`;
export const COMPANY_STOCKING_PARAMETER_URL = `${ANALYSIS_SERVICE_URL}/company-stocking-parameters`;
export const STOCKING_PARAMETER_URL = `${ANALYSIS_SERVICE_URL}/stocking-parameters`;
export const STOCKING_PARAMETER_CUSTOMIZABLE_URL = `${ANALYSIS_SERVICE_URL}/stocking-parameters-customizable`;
export const PARAMETER_CHART_CUSTOMIZABLE_URL = `${ANALYSIS_SERVICE_URL}/parameters-chart-customizable`;
export const HARVESTS_URL = `${ANALYSIS_SERVICE_URL}/harvests`;

// Balance URLS
export const CLIENT_BALANCES_URL = `${BALANCE_SERVICE_URL}/client-balances`;
export const COMPANY_BALANCES_URL = `${BALANCE_SERVICE_URL}/company-balances`;
export const LOG_BALANCES_BY_COMPANY_URL = `${BALANCE_SERVICE_URL}/log-balances-by-company`;
export const LOG_BALANCES_BY_USER_URL = `${BALANCE_SERVICE_URL}/log-balances-by-user`;
export const LOG_BALANCES_URL = `${BALANCE_SERVICE_URL}/log-balances`;
export const BALANCE_USAGE_URL = `${BALANCE_SERVICE_URL}/balance-usage`;
export const PLANS_URL = `${BALANCE_SERVICE_URL}/plans`;
export const PAYMENTS_URL = `${BALANCE_SERVICE_URL}/payments`;
export const HISTORICAL_COMPANY_BALANCES_URL = `${BALANCE_SERVICE_URL}/historical-company-balances`;
export const PREPAID_BALANCE_USAGE_URL = `${BALANCE_SERVICE_URL}/prepaid-balance-usage`;
export const POSTPAID_TRANSFER_URL = `${PAYMENTS_URL}/postpaid/transfer`;
export const POSTPAID_CSV_PAYMENT_URL = `${PAYMENTS_URL}/postpaid/csv`;

// Metrics URLS
export const METRICS_URL = `${ANALYSIS_SERVICE_URL}/analysis-metrics`;
export const METRICS_URL_LARVAE = `${METRICS_URL}/larvae`;
export const METRICS_URL_JUVENILE = `${METRICS_URL}/juvenile`;
export const METRICS_URL_ADULT = `${METRICS_URL}/grow-out`;
export const COMPANY_METRICS_URL_LARVAE = `${METRICS_URL}/company/larvae`;
export const COMPANY_METRICS_URL_JUVENILE = `${METRICS_URL}/company/juvenile`;
export const COMPANY_METRICS_URL_ADULT = `${METRICS_URL}/company/grow-out`;
export const EXCLUDE_URL = `${METRICS_URL}/exclude`;
export const INCLUDE_URL = `${METRICS_URL}/include`;
export const EXCLUDE_CHART_URL = `${METRICS_URL}/chart/exclude`;
export const INCLUDE_CHART_URL = `${METRICS_URL}/chart/include`;
export const MULTIPHASE_URL = `${METRICS_URL}/multiphase`;
export const LABORATORY_METRIC_URL = `${METRICS_URL}/lab-name`;

// Clients URLS
export const SEARCH_CLIENTS_URL = `${CLIENTS_URL}/search/byParams`;

// Users URLS
export const SEARCH_USERS_URL = `${USERS_URL}/search/byParams`;
export const SEARCH_SELLER_URL = `${USERS_URL}/search/by-sales-role`;
export const SEARCH_PROMOTERS_URL = `${USERS_URL}/search/by-promoter-role`;
export const SEARCH_UNASSIGNED_PROMOTERS_URL = `${USERS_URL}/search/unassigned-promoters`;

// Analysis Custom URLS
export const API_TOKEN_CUSTOM_URL = `${ANALYSIS_API_URL}/settings/api-token`;
export const WEB_HOOK_URL = `${ANALYSIS_API_URL}/settings/web-hook`;

// Payphone
export const PAYPHONE_URL = 'https://pay.payphonetodoesposible.com/api';
export const PAYPHONE_BUTTON_PREPARE_URL = `${PAYPHONE_URL}/button/prepare`;
export const PAYPHONE_BUTTON_CONFIRM_URL = `${PAYPHONE_URL}/button/v2/confirm`;

export const STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';

// AWS API
const XLSX_ANALYSIS_REPORT_APIG_ID = {
  [environments.DEV]: 'vq4317d94e',
  [environments.STG]: '1n06w5b7a3',
  [environments.PROD]: 'yk77jwgzrk'
};
export const XLSX_ANALYSIS_REPORT_URL = `https://${XLSX_ANALYSIS_REPORT_APIG_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/main`;

const XLSX_PAYMENTS_REPORT_API_ID = {
  [environments.DEV]: '9ra1mjr0k3',
  [environments.STG]: 'zwtdzjx3ba',
  [environments.PROD]: '2j3a3m9rhc'
};
export const XLSX_PAYMENTS_REPORT_URL = `https://${XLSX_PAYMENTS_REPORT_API_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/main`;

const PRESIGNED_URL_API_ID = {
  [environments.DEV]: 'frudpx5qy1',
  [environments.STG]: 'kdi3ximsfj',
  [environments.PROD]: '994rrur7v9',
};
export const GENERATE_PRESIGNED_URL = `https://${PRESIGNED_URL_API_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/presigned`;

const XLSX_ACTIVE_TANKS_REPORT_API_ID = {
  [environments.DEV]: '2sy2tt7k1e',
  [environments.STG]: 'sy05nhdz10',
  [environments.PROD]: 'ai7w9vejfc'
};
export const XLSX_ACTIVE_TANKS_REPORT_URL = `https://${XLSX_ACTIVE_TANKS_REPORT_API_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/main`;

const XLSX_ANALYSIS_ACTIVITY_REPORT_API_ID = {
  [environments.DEV]: 'cnyduknhuf',
  [environments.STG]: 'wd7x84akg3',
  [environments.PROD]: '9imi65nmwk'
};
export const XLSX_ANALYSIS_ACTIVITY_REPORT_URL = `https://${XLSX_ANALYSIS_ACTIVITY_REPORT_API_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/main`;

const GENERATE_JUVENILE_MASK_APIG_ID = 'f2jzk0a3jc';
export const GENERATE_JUVENILE_MASK_URL = `https://${GENERATE_JUVENILE_MASK_APIG_ID}.execute-api.us-east-1.amazonaws.com/dev/generate-juvenile-mask`;

const CONSOLIDATED_STOCKING_API_ID = {
  [environments.DEV]: 'beb5pzujql',
  [environments.STG]: '473rzg9m9h',
  [environments.PROD]: 'yxr6kz8u2m'
};
export const CONSOLIDATED_STOCKING_URL = `https://${CONSOLIDATED_STOCKING_API_ID[ENVIRONMENT]}.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT}/consolidate-harvests`;
